import { Box, Button, Container, FormControl, FormErrorMessage, FormLabel, Input, Link, VStack } from "@chakra-ui/react"
import { Formik, Field } from "formik"
import { useState } from "react"
import Parse from 'parse'
import { useNavigate } from "react-router-dom"

const Auth = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const onSignin = async (values: any) => {
        console.log("SIGN IN: ", values)
        setLoading(true)
        try {
            await Parse.User.logIn(values.email, values.password)
            navigate('/news')
            setLoading(false)
        } catch (error: any) {
            setLoading(false)
        }

    }

    return (
        <Container>
            <VStack w="100%">
                <Box w="100%">
                    <Formik initialValues={{
                        email: '',
                        password: ''
                    }}
                        onSubmit={(values) => onSignin(values)}>
                        {({ handleSubmit, errors, touched }) => (
                            <form onSubmit={handleSubmit}>
                                <FormControl isInvalid={!!errors.email} mt="1rem">
                                    <FormLabel>Username / Email</FormLabel>
                                    <Field
                                        as={Input}
                                        id="email"
                                        name="email"
                                    />
                                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                                </FormControl>

                                <FormControl isInvalid={!!errors.password}>
                                    <FormLabel>Password</FormLabel>
                                    <Field
                                        as={Input}
                                        id="password"
                                        name="password"
                                        type="password"
                                    />
                                    <FormErrorMessage>{errors.password}</FormErrorMessage>
                                </FormControl>

                                {!loading &&
                                    <Button type="submit" mt="2rem" mr={3} w="100%">
                                        Sign In
                                    </Button>
                                }
                            </form>
                        )}
                    </Formik>
                </Box>
            </VStack>
        </Container>
    )
}

export default Auth