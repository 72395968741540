import { useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Heading, Progress, Divider, VStack, Text, Flex } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import Parse from 'parse'

interface ApplicationModalProps {
    username: string,
    applicationType: string
    applicationId: string,
    open: boolean,
    onClose: () => void,
    onProcessClick: (approved: boolean) => void
}

const ApplicationModal = ({ applicationId, username, applicationType, open, onClose, onProcessClick }: ApplicationModalProps) => {
    const [loading, setLoading] = useState(false)
    const [answers, setAnswers] = useState<any[]>([])

    useEffect(() => {
        getAnswers()
    }, [])

    const getAnswers = async () => {
        setLoading(true)
        const response = await Parse.Cloud.run('applicationDetail', { applicationId })
        setAnswers(response.typeFormResponse)
        setLoading(false)
    }

    const handleProcessClick = (approved: boolean) => {
        onProcessClick(approved)
        onClose()
    }

    return (
        <>
            <Modal isOpen={open} onClose={onClose} scrollBehavior="inside">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Application</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Heading size="md">{username}</Heading>
                        <Heading size="sm">{applicationType}</Heading>

                        {loading ?
                            <Progress isIndeterminate w="100%" my="2rem" />
                            :
                            <>
                                {answers.map(answer => (
                                    <>
                                        <Divider my="1rem"></Divider>
                                        <Heading mb="0.5rem" textAlign="left" size="sm">{answer.title}</Heading>
                                        <Text>{answer.response || 'NO ANSWER'}</Text>
                                    </>
                                ))}

                            </>
                        }
                    </ModalBody>

                    <ModalFooter>
                        {!loading &&
                            <Flex>
                                <Button onClick={() => handleProcessClick(false)} colorScheme='red' mr={3}>
                                    Decline
                                </Button>
                                <Button colorScheme='green' mr={3} onClick={() => handleProcessClick(true)}>
                                    Approve
                                </Button>
                            </Flex>
                        }
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ApplicationModal