import { Button, Center, Container, Heading, HStack, IconButton, Progress, Table, TableContainer, Tbody, Td, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom";
import Parse from 'parse'
import { useEffect, useState } from "react";
import { FiExternalLink } from "react-icons/fi";
import { Switch } from '@chakra-ui/react'

export const Opportunities = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState([])
    const [activeLoading, setActiveLoading] = useState(false)

    useEffect(() => {
        getUsers()
        const query = new Parse.Query(Parse.User)
        const users = query.find()
        console.log("USERS: ", users)
    }, [])

    const getUsers = async () => {
        setUsers([])
        setLoading(true)
        const _users = await Parse.Cloud.run('getUsersAdmin')
        setUsers(_users)
        setLoading(false)
    }

    const onActiveChange = async (value: boolean, userId: string) => {
        setActiveLoading(true)
        await Parse.Cloud.run('setVerifiedReferrer', { value, userId })
        setActiveLoading(false)
    }

    return (
        <VStack w="100%" spacing="2rem">
            <HStack w="100%" justify="start">
                <Heading>Users</Heading>
            </HStack>

            {loading ?
                <Progress isIndeterminate w="100%" my="2rem" />
                :
                <TableContainer w="100%">
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Username</Th>
                                <Th>First Name</Th>
                                <Th>Last Name</Th>
                                <Th>Referrer</Th>
                                <Th>Email</Th>
                                <Th>Email Verified</Th>
                                <Th>Verified</Th>
                                <Th>Created</Th>
                                <Th>Updated</Th>
                                <Th>Verified Referrer</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {users.map((user: Parse.User) => (
                                <Tr>
                                    <Td>{user.getUsername()}</Td>
                                    <Td>{user.get('firstName')}</Td>
                                    <Td>{user.get('lastName')}</Td>
                                    <Td>{user.get('referrer')}</Td>
                                    <Td>{user.getEmail()}</Td>
                                    <Td>{user.get('emailVerified') ? 'Yes' : 'No'}</Td>
                                    <Td>{user.get('verified') ? 'Yes' : 'No'}</Td>
                                    <Td>{user.get('createdAt').toLocaleDateString()}</Td>
                                    <Td>{user.get('updatedAt').toLocaleDateString()}</Td>
                                    <Td><Switch onChange={(e) => onActiveChange(e.target.checked, user.id)} disabled={activeLoading} defaultChecked={user.get('verifiedReferrer')}></Switch></Td> 
                                    <Td><IconButton onClick={() => navigate('/users/' + user.id)} aria-label={""}><FiExternalLink /></IconButton></Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            }
        </VStack>
    )
}

export default Opportunities