import React, { useEffect, useState } from 'react';
import { Route, Navigate } from 'react-router-dom';
import Parse from 'parse'

interface ProtectedRouteProps {
    children: React.ReactNode
}
const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
    const [currentUser, setCurrentUser] = useState<Parse.User | undefined>()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        initUser()
    }, [])

    const initUser = async () => {
        const user = await Parse.User.current()
        setCurrentUser(user)
        setLoading(false)
    }

    if (!currentUser && !loading) {
        return <Navigate to="/" replace />;
    }
    return <React.Fragment>{children}</React.Fragment>
};

export default ProtectedRoute
