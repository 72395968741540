import { Button, Center, Container, Heading, HStack, IconButton, Progress, Table, TableContainer, Tbody, Td, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom";
import Parse from 'parse'
import { useEffect, useState } from "react";
import { FiExternalLink } from "react-icons/fi";
import { Switch } from '@chakra-ui/react'

export const Opportunities = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [opportunities, setOpportunities] = useState([])
    const [activeLoading, setActiveLoading] = useState(false)

    useEffect(() => {
        getOpportunities()
    }, [])

    const getOpportunities = async () => {
        setOpportunities([])
        setLoading(true)
        const _opportunities = await Parse.Cloud.run('allOpportunities')
        setOpportunities(_opportunities)
        setLoading(false)
    }

    const onActiveChange = async (value: boolean, opportunityId: string) => {
        setActiveLoading(true)
        await Parse.Cloud.run('activateOpportunity', { value, opportunityId })
        setActiveLoading(false)
        getOpportunities()
    }

    return (
        <VStack w="100%" spacing="2rem">
            <HStack w="100%" justify="space-between">
                <Heading>Opportunities</Heading>
                <Button onClick={() => navigate('/opportunities/new')}>+ New</Button>
            </HStack>

            {loading ?
                <Progress isIndeterminate w="100%" my="2rem" />
                :
                <TableContainer w="100%">
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Date</Th>
                                <Th>Title</Th>
                                {/* <Th>Content</Th> */}
                                <Th>Is Active</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {opportunities.map((o: Parse.Object) => (
                                <Tr>
                                    <Td>{(o.get('createdAt') as Date).toLocaleDateString()}</Td>
                                    <Td>{o.get('title')}</Td>
                                    {/* <Td>{o.get('content')}</Td> */}
                                    <Td><Switch onChange={(e) => onActiveChange(e.target.checked, o.id)} disabled={activeLoading} defaultChecked={o.get('active')}></Switch></Td>
                                    <Td><IconButton onClick={() => navigate('/opportunities/' + o.id)} aria-label={""}><FiExternalLink /></IconButton></Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            }
        </VStack>
    )
}

export default Opportunities