import React, { ReactNode, useEffect, useState } from 'react';
import {
    IconButton,
    Box,
    CloseButton,
    Flex,
    Icon,
    useColorModeValue,
    Drawer,
    DrawerContent,
    Image,
    useDisclosure,
    BoxProps,
    FlexProps,
    VStack,
    Button,
    Link
} from '@chakra-ui/react';
import {
    FiMenu,
} from 'react-icons/fi';

import { ReactText } from 'react';
// import LogoDark from '../../images/Linkerfi-labs-logo-lightm.svg';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import Parse from 'parse'

interface LinkItemProps {
    route: string
    name: string
}

const LinkItems: Array<LinkItemProps> = [
    { route: '', name: 'Home' },
    { route: 'users', name: 'Users' },
    { route: 'news', name: 'News' },
    { route: 'opportunities', name: 'Opportunities' },
    { route: 'applications', name: 'Applications' },
    { route: '/', name: 'SignOut' },
];

export default function SimpleSidebar({ children }: { children: ReactNode }) {
    const [marketStats, setMarketStats] = useState('')
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        getMarketStats()
    }, [])

    const getMarketStats = async () => {
        const marketStats = await Parse.Cloud.run('ticker')
        setMarketStats(marketStats)
    }

    return (
        <Box>
            <Box minH="100vh">
                <SidebarContent
                    onClose={() => onClose()}
                    display={{ base: 'none', md: 'block' }}
                />
                <Drawer
                    autoFocus={false}
                    isOpen={isOpen}
                    placement="left"
                    onClose={onClose}
                    returnFocusOnClose={false}
                    onOverlayClick={onClose}
                    size="full">
                    <DrawerContent>
                        <SidebarContent onClose={onClose} />
                    </DrawerContent>
                </Drawer>
                {/* mobilenav */}
                <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
                <Box ml={{ base: 0, md: 60 }} p="4">
                    {children}
                </Box>
            </Box>
        </Box>
    );
}

interface SidebarProps extends BoxProps {
    onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
    const location = useLocation()
    const currentUser = Parse.User.current()
    const navigate = useNavigate()

    const onClick = (navItem: LinkItemProps) => {
        if (navItem.name === 'SignOut') {
            Parse.User.logOut()
            navigate('/')
        }
        onClose()
    }

    return (
        <Box
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            zIndex={2}
            {...rest}>
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                {/* <Image src={LogoDark} width="120px" /> */}
                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Flex>

            {LinkItems.map((link, index) => (
                <NavItem route={link.route} onClick={() => onClick(link)} active={location.pathname.split('/')[1] === link.route} key={link.name}>
                    {link.name}
                </NavItem>
            ))}
        </Box>
    );
};

interface NavItemProps extends FlexProps {
    active: boolean
    children: ReactText;
    route: string
}
const NavItem = ({ route, active, children, ...rest }: NavItemProps) => {
    return (
        <Link as={RouterLink} to={route} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                p="3"
                mx="4"
                my={1}
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: '#202020',
                    color: 'white',
                }}
                bg={active ? '#202020' : 'inherit'}
                color={active ? 'white' : 'inherit'}
                {...rest}>
                {children}
            </Flex>
        </Link>
    );
};

interface MobileProps extends FlexProps {
    onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 24 }}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent="flex-start"
            {...rest}>
            <IconButton
                variant="outline"
                onClick={onOpen}
                aria-label="open menu"
                icon={<FiMenu />}
            />

            {/* <Image ml={8} src={LogoDark} width="120px" /> */}
        </Flex>
    );
};