import { Button, Center, Container, FormControl, FormErrorMessage, FormLabel, Heading, HStack, IconButton, Input, Progress, Table, TableContainer, Tbody, Td, Textarea, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Parse from 'parse'
import { useEffect, useState } from "react";
import { FiExternalLink } from "react-icons/fi";
import { Formik, Field } from "formik";
import { Select } from "chakra-react-select";

interface OpportunityForm {
    title: string
    content: string
    userRoles: string[]
}

interface Option {
    value: string
    label: string;
}

export const Opportunity = () => {
    const navigate = useNavigate();
    const queryParams = useParams()

    const [opportunityId, setOpportunityId] = useState<string | undefined>()
    const [loading, setLoading] = useState(false)
    const [opportunities, setOpportunities] = useState([])
    const [initialFormValue, setInitialFormValue] = useState<OpportunityForm>({
        title: '',
        content: '',
        userRoles: []
    })
    
    const [userRoleOptions, setUserRoleOptions] = useState<Option[]>([])

    useEffect(() => {
        console.log("Q: ", queryParams)
        if (queryParams.opportunityId) {
            setOpportunityId(queryParams.opportunityId)
            initExisting(queryParams.opportunityId)
        } else {
            initNew()
        }
        getUserRoles()
    }, [queryParams])

    const getUserRoles = async () => {
        const userRoles = await Parse.Cloud.run('getUserRoles')
        setUserRoleOptions(userRoles.map((o: Parse.Object) => ({ value: o.id, label: o.get('name') })))
    }

    const initNew = async () => {
        setLoading(true)
        const userRoles = await Parse.Cloud.run('getUserRoles')
        setUserRoleOptions(userRoles.map((o: Parse.Object) => ({ value: o.id, label: o.get('name') })))
        setLoading(false)
    }

    const initExisting = async (opportunityId: string) => {
        setLoading(true)
        const [userRoles, opportunity] = await Promise.all([Parse.Cloud.run('getUserRoles'), Parse.Cloud.run('getOpportunity', { opportunityId })])
        setUserRoleOptions(userRoles.map((o: Parse.Object) => ({ value: o.id, label: o.get('name') })))
        setInitialFormValue({
            title: opportunity.get('title'),
            content: opportunity.get('content'),
            userRoles: opportunity.get('userRoles').map((u: Parse.Object) => u.id)
        })
        setLoading(false)
    }

    const onSubmit = async (values: OpportunityForm) => {
        setLoading(true)
        if (opportunityId) {
            await Parse.Cloud.run('updateOpportunity', {...values, opportunityId})
        } else {
            await Parse.Cloud.run('createOpportunity', values)
        }
        navigate('/opportunities')
    }

    return (
        <Container size="100">
            <VStack w="100%" spacing="2rem">
                <Heading>Opportunities</Heading>

                {loading ?
                    <Progress isIndeterminate w="100%" my="2rem" />
                    :
                    <Formik initialValues={initialFormValue}
                        onSubmit={(values) => onSubmit(values)}
                    >
                        {({ handleSubmit, errors, touched, setFormikState, values, setFieldValue }) => (
                            <form style={{ width: '100%' }} onSubmit={handleSubmit}>
                                <FormControl isInvalid={!!errors.title} mt="1rem">
                                    <FormLabel>Title</FormLabel>
                                    <Field
                                        as={Input}
                                        id="title"
                                        name="title"
                                    />
                                    <FormErrorMessage>{errors.title}</FormErrorMessage>
                                </FormControl>

                                <FormControl isInvalid={!!errors.content} mt="1rem">
                                    <FormLabel>Content</FormLabel>
                                    <Field
                                        as={Textarea}
                                        id="content"
                                        name="content"
                                    />
                                    <FormErrorMessage>{errors.content}</FormErrorMessage>
                                </FormControl>

                                <FormControl>
                                    <FormLabel>User Roles</FormLabel>
                                    <Select
                                        placeholder="select"
                                        isMulti
                                        options={userRoleOptions}
                                        closeMenuOnSelect={false}
                                        defaultValue={initialFormValue.userRoles.map(u => ({value: u, label: userRoleOptions.find(uro => uro.value === u)?.label}))}
                                        onChange={(option: any) => setFieldValue('userRoles', option.map((o: any) => o.value))}
                                    />

                                    {/* <Field
                                as={Select}
                                id="userRoles"
                                name="userRoles"
                                placeholder="select"
                                options={[{ value: '1', label: 'first' }, { value: '2', label: 'second' }]}
                                onChange={
                                    (option: any) => {
                                        console.log("OPTION: ", values.userRoles)
                                        if (values.userRoles.includes(option.value)) {
                                            setFieldValue('userRoles', values.userRoles.filter(v => v !== option.value))
                                        } else {
                                            setFieldValue('userRoles', [... values.userRoles, option.value])
                                        }
                                    }
                                }
                            /> */}
                                    <FormErrorMessage>{errors.content}</FormErrorMessage>

                                    {/* {isLoading ? (
                                <div>Loading</div>
                            ) : error ? (
                                <div>Error</div>
                            ) : data ? (
                                <Box>
                                    <Select
                                        defaultValue={defaultValue(data, field.value)}
                                        placeholder="Select an value..."
                                        options={data}
                                        onChange={
                                            (option: Option) => setFieldValue(field.name, option.value)
                                        }
                                    />
                                </Box>
                            ) : null} */}
                                </FormControl>

                                {!loading &&
                                    <Button type="submit" mt="2rem" mr={3} w="100%">
                                        Submit
                                    </Button>
                                }
                            </form>
                        )}
                    </Formik>
                }
            </VStack>
        </Container>
    )
}

export default Opportunity