import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { ApplicationsPage, AuthPage, NewsPage, OpportunitiesPage, OpportunityPage, UsersPage } from './pages';
import ProtectedRoute from './ProtectedRoute';
import Parse from 'parse'
import Layout from './layout';

Parse.initialize("linkerfi", "");
Parse.serverURL = process.env.REACT_APP_SERVER_URL || 'http://localhost:1337/parse'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  
  <React.StrictMode>
    <ChakraProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<AuthPage />} />
            <Route path="/news" element={<ProtectedRoute><NewsPage /></ProtectedRoute>} />
            <Route path="/users" element={<ProtectedRoute><UsersPage /></ProtectedRoute>} />
            <Route path="/opportunities" element={<ProtectedRoute><OpportunitiesPage /></ProtectedRoute>} />
            <Route path="/opportunities/new" element={<ProtectedRoute><OpportunityPage /></ProtectedRoute>} />
            <Route path="/opportunities/:opportunityId" element={<ProtectedRoute><OpportunityPage /></ProtectedRoute>} />
            <Route path="/applications" element={<ProtectedRoute><ApplicationsPage /></ProtectedRoute>} />
          </Routes>
        </Layout>
      </Router>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
