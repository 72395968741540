import { Button, Center, Container, FormLabel, Heading, HStack, IconButton, Progress, Table, TableContainer, Tbody, Td, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom";
import Parse from 'parse'
import { useEffect, useState } from "react";
import { FiExternalLink, FiEye } from "react-icons/fi";
import { Select } from '@chakra-ui/react'
import { ApplicationModal } from "../../components";

export const Applications = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [applications, setApplications] = useState([])
    const [statusId, setStatusId] = useState(0)
    const [selectedApplication, setSelectedApplication] = useState<Parse.Object | undefined>()

    useEffect(() => {
        getApplications()
    }, [])

    const getApplications = async () => {
        setLoading(true)
        const _applications = await Parse.Cloud.run('adminApplications', { statusId })
        setApplications(_applications)
        setLoading(false)
    }

    useEffect(() => {
        getApplications()
    }, [statusId])

    const statusName = (statusId: number): string => {
        switch (statusId) {
            case 0:
                return 'pending'
            case 0:
                return 'approved'
            default:
                return 'denied'
        }
    }

    const processApplication = async (approved: boolean) => {
        setLoading(true)
        await Parse.Cloud.run('processApplication', { applicationId: selectedApplication?.id, approved })
        getApplications()
    }


    return (
        // <Container size="100">
        <VStack w="100%" spacing="2rem">
            <Heading>Applicaitons</Heading>

            <FormLabel>Filter:</FormLabel>

            <Select placeholder='Select a filter' value={statusId} onChange={(e) => setStatusId(parseInt(e.target.value))}>
                <option value={0}>Pending</option>
                <option value={1}>Approved</option>
                <option value={2}>Declined</option>
            </Select>

            {loading ?
                <Progress isIndeterminate w="100%" my="2rem" />
                :
                <TableContainer w="100%">
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Date</Th>
                                <Th>User</Th>
                                <Th>Role</Th>
                                <Th>Status</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {applications.map((o: Parse.Object) => (
                                <Tr>
                                    <Td>{(o.get('createdAt') as Date).toLocaleDateString()}</Td>
                                    <Td>{`${o.get('user').get('firstName')} ${o.get('user').get('lastName')} (${o.get('user').get('username')})`}</Td>
                                    <Td>{o.get('userRole').get('name')}</Td>
                                    <Td>{statusName(o.get('status'))}</Td>
                                    <Td><IconButton onClick={() => setSelectedApplication(o)} aria-label={""}><FiEye /></IconButton></Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            }
            {
                selectedApplication &&
                <ApplicationModal
                    open={true}
                    onClose={() => setSelectedApplication(undefined)}
                    username={`${selectedApplication.get('user').get('firstName')} ${selectedApplication.get('user').get('lastName')} (${selectedApplication.get('user').get('username')})`}
                    applicationType={selectedApplication.get('userRole').get('name')}
                    applicationId={selectedApplication.id}
                    onProcessClick={(approved: boolean) => processApplication(approved)}
                />
            }
        </VStack>
    )
}

export default Applications