import { Center, Container, Heading } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom";

export const News = () => {
    const navigate = useNavigate();

    return (
        <Container>
            <Center>
                <Heading>NEWS</Heading>
            </Center>
        </Container>
    )
}

export default News